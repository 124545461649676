import { Card, Layout } from 'antd';
import { NormalProgrammeComponent, MainSubStructure, ExportModal, ImportModal } from 'egenie-utils';
import { Provider } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';

const store = new Store();
export default function() {
  return (
    <>
      <Provider store={store}>
        <Layout className={styles.container}>
          <Layout.Content>
            <Card size="small">
              <NormalProgrammeComponent store={store.normalProgramme}/>
            </Card>
            <div className={styles.tableWrapper}>
              <MainSubStructure store={store.mainSubStructureModel}/>
            </div>
          </Layout.Content>
        </Layout>
        <ExportModal store={store.exportStore}/>
      </Provider>
      <ImportModal store={store.importModel}/>
    </>

  );
}

