export const enumPushPurchase = {
  enable: {
    value: '1',
    label: '启用',
  },
  disabled: {
    value: '0',
    label: '禁用',
  },
};

export const enumVendorPrint = {
  enable: {
    value: '1',
    label: '启用',
  },
  disabled: {
    value: '0',
    label: '禁用',
  },
};
