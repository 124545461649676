import { message, Modal, Space } from 'antd';
import type { BaseData, PaginationData } from 'egenie-utils';
import { Permission, MainSubStructureModel, NormalProgramme, request, TimeStampFormatter, ExportStore, ImportModel } from 'egenie-utils';
import React from 'react';
import { LogTooltip } from '../../utils';
import { enumPushPurchase, enumVendorPrint } from './constants';
import type { MainItem } from './types';
import { getCustomPrintParam } from 'egenie-common';

export class Store {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public importModel: ImportModel = new ImportModel();

  private handleCancelTemplate = (row: MainItem) => {
    Modal.confirm({
      content: '确认取消模板吗?',
      onOk: async() => {
        await request({
          method: 'post',
          url: '/api/cloud/baseinfo/rest/vendor/set/print/temp',
          data: { cloudPmsVendorId: row.cloudPmsVendorId },
        });
        this.mainSubStructureModel.gridModel.onRefresh();
        message.success('取消模板成功');
      },
    });
  };

  private handleSetTemplate = async(row: MainItem) => {
    const templateData = await getCustomPrintParam('27', { onlyChooseTemplate: true });
    await request({
      method: 'post',
      url: '/api/cloud/baseinfo/rest/vendor/set/print/temp',
      data: {
        cloudPmsVendorId: row.cloudPmsVendorId,
        printTempId: templateData.templateId,
      },
    });

    this.mainSubStructureModel.gridModel.onRefresh();
    message.success('设置模板成功');
  };

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '同步供应商',
        permissionId: '4007',
        handleClick: () => {
          Modal.confirm({
            content: '确认同步供应商吗?',
            onOk: async() => {
              const info = await request<BaseData<string>>({
                method: 'post',
                url: '/api/cloud/pms/vendor/syncVendorInfo',
              });
              message.success(`${info.info}` || '同步供应商成功');
            },
          });
        },
      },
      {
        text: '启用采购单推送',
        permissionId: '301',
        icon: 'icon-on',
        handleClick: () => {
          const selectRows: MainItem[] = this.mainSubStructureModel.gridModel.selectRows;
          if (selectRows.length === 0) {
            const error = '请选择数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确认启用采购单推送吗?',
            onOk: async() => {
              await request({
                method: 'post',
                url: '/api/cloud/pms/vendor/updateVendorPushStatus',
                data: {
                  cloudPmsVendorIds: selectRows.map((item) => item.cloudPmsVendorId),
                  status: enumPushPurchase.enable.value,
                },
              });

              message.success('启用采购单推送成功');
              this.mainSubStructureModel.gridModel.resetAll();
              this.mainSubStructureModel.gridModel.onRefresh();
            },
          });
        },
      },
      {
        text: '禁用采购单推送',
        permissionId: '300',
        icon: 'icon-off',
        handleClick: () => {
          const selectRows: MainItem[] = this.mainSubStructureModel.gridModel.selectRows;
          if (selectRows.length === 0) {
            const error = '请选择数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确认禁用采购单推送吗?',
            onOk: async() => {
              await request({
                method: 'post',
                url: '/api/cloud/pms/vendor/updateVendorPushStatus',
                data: {
                  cloudPmsVendorIds: selectRows.map((item) => item.cloudPmsVendorId),
                  status: enumPushPurchase.disabled.value,
                },
              });

              message.success('禁用采购单推送成功');
              this.mainSubStructureModel.gridModel.resetAll();
              this.mainSubStructureModel.gridModel.onRefresh();
            },
          });
        },
      },
      {
        text: '启用供应商打印',
        permissionId: '4008',
        icon: 'icon-on',
        handleClick: () => {
          const selectRows: MainItem[] = this.mainSubStructureModel.gridModel.selectRows;
          if (selectRows.length === 0) {
            const error = '请选择数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确认启用供应商打印吗?',
            onOk: async() => {
              await request({
                method: 'post',
                url: '/api/cloud/pms/vendor/updateVendorPrintStatus',
                data: {
                  cloudPmsVendorIds: selectRows.map((item) => item.cloudPmsVendorId),
                  status: enumVendorPrint.enable.value,
                },
              });

              message.success('启用供应商打印成功');
              this.mainSubStructureModel.gridModel.resetAll();
              this.mainSubStructureModel.gridModel.onRefresh();
            },
          });
        },
      },
      {
        text: '禁用供应商打印',
        permissionId: '4009',
        icon: 'icon-off',
        handleClick: () => {
          const selectRows: MainItem[] = this.mainSubStructureModel.gridModel.selectRows;
          if (selectRows.length === 0) {
            const error = '请选择数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确认禁用供应商打印吗?',
            onOk: async() => {
              await request({
                method: 'post',
                url: '/api/cloud/pms/vendor/updateVendorPrintStatus',
                data: {
                  cloudPmsVendorIds: selectRows.map((item) => item.cloudPmsVendorId),
                  status: enumVendorPrint.disabled.value,
                },
              });

              message.success('禁用供应商打印成功');
              this.mainSubStructureModel.gridModel.resetAll();
              this.mainSubStructureModel.gridModel.onRefresh();
            },
          });
        },
      },
      {
        permissionId: '4031',
        text: '导入打印顺序',
        icon: 'icon-import',
        handleClick: () => {
          this.importModel.openModal({ sheetName: 'cloud_vendor_setting' });
        },
      },
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds)
            .join(',');
          const fileName = '供应商';
          const exportType = 'cloud_vendor_manage';
          const queryParam = this.normalProgramme.filterItems.params;
          const queryParamShow = this.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            this.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                this.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    grid: {
      columns: [
        {
          key: '操作',
          name: '操作',
          width: 180,
          frozen: true,
          formatter: ({ row }) => (
            <Space size="small">
              <LogTooltip
                children={(
                  <a>
                    日志
                  </a>
                )}
                entityId={row.cloudPmsVendorId}
                height={230}
                logBizModuleCode={110000}
                popoverProps={{
                  title: () => (
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      供应商管理日志
                    </div>
                  ),
                  trigger: 'click',
                  placement: 'left',
                }}
                width={810}
              />
              <Permission permissionId="60014_6057">
                <a onClick={() => this.handleSetTemplate(row)}>
                  设置模板
                </a>
              </Permission>
              <Permission permissionId="60014_6058">
                <a onClick={() => this.handleCancelTemplate(row)}>
                  取消模板
                </a>
              </Permission>
            </Space>
          ),
        },
        {
          key: 'vendorName',
          name: '供应商名称',
          width: 150,
        },
        {
          key: 'mobile',
          name: '手机号码',
          width: 100,
        },
        {
          key: 'cloudPmsVendorNo',
          name: '供应商编号',
          width: 150,
        },
        {
          key: 'vendorUserName',
          name: '供应商账号',
          width: 150,
        },
        {
          key: 'level',
          name: '供应商等级',
          width: 80,
        },
        {
          key: 'briefName',
          name: '简称',
          width: 150,
        },
        {
          key: 'address',
          name: '地址',
          width: 150,
        },
        {
          key: 'industryTypeName',
          name: '行业',
          width: 100,
        },
        {
          key: 'contact',
          name: '联系人',
          width: 100,
        },
        {
          key: 'pushPurchase',
          name: '推送销售订单',
          width: 100,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <span>
                {row.pushPurchase ? enumPushPurchase.enable.label : enumPushPurchase.disabled.label}
              </span>
            );
          },
        },
        {
          key: 'vendorPrint',
          name: '供应商打印',
          width: 100,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <span>
                {row.vendorPrint ? enumVendorPrint.enable.label : enumVendorPrint.disabled.label}
              </span>
            );
          },
        },
        {
          key: 'lastUpdateTime',
          name: '更新时间',
          width: 150,
          formatter: ({ row }: { row: MainItem; }) => {
            return <TimeStampFormatter value={row.lastUpdateTime}/>;
          },
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }: { row: MainItem; }) => {
            return <TimeStampFormatter value={row.createTime}/>;
          },
        },
        {
          key: 'realNameApplyStatus',
          name: '是否实名',
          width: 70,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <span>
                {row.realNameApplyStatus ? '是' : '否'}
              </span>
            );
          },
        },
        {
          key: 'remark',
          name: '备注',
          width: 150,
        },
        {
          key: 'printPriority',
          name: '导入顺序',
          width: 80,
        },
        {
          key: 'printTempName',
          name: '模板名称',
          width: 150,
        },
        {
          key: 'cargoRights',
          name: '是否兜货权',
          width: 80,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <span>
                {row.cargoRights ? '是' : '否'}
              </span>
            );
          },
        },
        {
          key: 'stockToWarehouse',
          name: '是否备货',
          width: 80,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <span>
                {row.stockToWarehouse ? '是' : '否'}
              </span>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'cloudPmsVendorId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/supplierManage/index',
    },
    pageId: '60014',

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/pms/vendor/listPageByParam',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        field: 'vendorName',
        label: '供应商名称',
        type: 'input',
      },
      {
        field: 'cloudPmsVendorNo',
        label: '供应商编码',
        type: 'input',
      },
      {
        field: 'vendorPrint',
        label: '供应商打印',
        type: 'select',
        data: Object.values(enumVendorPrint),
      },
      {
        field: 'pushPurchase',
        label: '推送销售订单',
        type: 'select',
        data: Object.values(enumPushPurchase),
      },
      {
        field: 'mobile',
        label: '手机号',
        type: 'input',
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });
}
